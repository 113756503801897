import { type } from "jquery";
import axiosInstance from "../config/axios";
import AuthService from "../services/auth.service";
import {
  setUserSession,
  setTokenExpires,
  setUserMenus,
  setUserRole,
  setUserData,
  setUserPlan,
  removeUserSession,
  setRefreshToken,
} from "../utils/UserDetails";
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  RESET_PASSWORD,
  VERIFY_LINK,
  CHANGE_PASSWORD,
} from "./types";

export const loginUser =
  (loginInput, setSubmitting, setFieldValue, history) => async (dispatch) => {
    try {
      dispatch(setLoginErrorState());
      const resultData = await AuthService.userLogin(loginInput);

      if (resultData?.status && resultData.status === true) {
        let userData = resultData?.data ? resultData.data : "";
        if (userData?.user_role) {
          setUserMenus(userData?.menus);
          setUserData(userData);
          setUserSession(userData?.api_token);
          setUserRole(userData?.user_role);
          setUserPlan(userData?.plan);
          setRefreshToken(userData?.refresh_token);
          if (userData?.expiry_time) {
            setTokenExpires(userData?.expiry_time);
          }
          if (userData?.api_token && userData.api_token) {
            axiosInstance.interceptors.request.use(function (config) {
              config.headers.Authorization = `Bearer ${userData.api_token}`;
              return config;
            });
          }

          dispatch(setLoginSuccessState({ user_role: userData.user_role }));

          history.push("/");
        } else {
          history.push({
            pathname: "/interestmodal",
            state: {
              role: userData?.user_role,
              profileId: userData?.profile_id,
            },
          });
        }
      } else {
        dispatch(setLoginErrorState(resultData.message));
        setFieldValue("password", "", false);
        setSubmitting(false);
      }
    } catch (err) {
      dispatch(setLoginErrorState("Please Try Agian"));
      setFieldValue("password", "", false);
      setSubmitting(false);
    }
  };

export const userRegister =
  (formData, resetForm, setSubmitting, setFieldValue) => async (dispatch) => {
    let errorStatus = "Please Try Agian";

    try {
      const resultData = await AuthService.registerLearningHub(formData);

      if (resultData?.status && resultData.status === true) {
        dispatch(setRegisterSuccessState("Success"));
        resetForm();
      } else {
        errorStatus = resultData?.error ? resultData.error : errorStatus;
        dispatch(setRegisterErrorState(errorStatus));
        setFieldValue("password", "", false);
        setSubmitting(false);
      }
    } catch (err) {
      dispatch(setRegisterErrorState(errorStatus));
      setFieldValue("password", "", false);
      setSubmitting(false);
    }
  };

export const forgotPassword =
  (formData, resetForm, setSubmitting, setFieldValue) => async (dispatch) => {
    try {
      const resultData = await AuthService.forgotPassword(formData);
      if (resultData) {
        dispatch({
          type: RESET_PASSWORD,
          payload: resultData,
        });
      }
      resetForm();
      setSubmitting(false);
    } catch (err) {
      resetForm();
      setSubmitting(false);
      dispatch({
        type: RESET_PASSWORD,
        payload: { status: false },
      });
    }
  };

export const verifyLink = (token) => async (dispatch) => {
  try {
    const resultData = await AuthService.verifyLink(token);
    if (resultData) {
      dispatch({
        type: VERIFY_LINK,
        payload: resultData,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const changePassword =
  (formData, resetForm, setSubmitting, setFieldValue) => async (dispatch) => {
    try {
      const resultData = await AuthService.changePassword(formData);
      if (resultData) {
        dispatch({
          type: CHANGE_PASSWORD,
          payload: resultData,
        });
      }
      resetForm();
      setSubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };

export const userLogout = (history) => async () => {
  try {
    const resultData = await AuthService.userLogout();

    if (resultData?.status && resultData.status === true) {
      removeUserSession();
      history.push("/");
    } else {
      removeUserSession();
      history.push("/");
    }
  } catch (err) {
    console.log(err);
    removeUserSession();
    history.push("/");
  }
};

export const setLoginSuccessState = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

const setLoginErrorState = (data) => {
  return {
    type: LOGIN_ERROR,
    payload: data,
  };
};

export const setRegisterSuccessState = (data) => {
  return {
    type: REGISTER_SUCCESS,
    payload: data,
  };
};

const setRegisterErrorState = (data) => {
  return {
    type: REGISTER_ERROR,
    payload: data,
  };
};
