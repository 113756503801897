import React, { useEffect } from "react";
import Header from "../../components/layout/Header";

const PrivacyPolicy = (props) => {

  return (
    <>
      <div>
        <Header />
        <div
          className="d-flex flex-column align-items-center justify-content-center container  bg-light"
          style={{ marginTop: "7rem" }}
        >
          <div
            className="card p-4 p-md-5 shadow-lg w-100 mx-3 mx-md-0"
          >
            <>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Open Sans !important;\nfont-size: 24px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Open Sans !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Open Sans !important;\nfont-size: 19px !important;\ncolor: #444444 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Open Sans !important;\nfont-size: 16px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 13px !important;\nfont-family: Open Sans !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 13px !important;\nfont-family: Open Sans !important;\nword-break: break-word !important;\n        }\n"
                }}
              />
              <div style={{ textAlign: "center" }}>
                <h4 className="title-css" style={{ fontWeight: 700 }}>PRIVACY POLICY</h4>
              </div>
              <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div
                className="MsoNormal subtittle-css"
                style={{ lineHeight: "1.5" }}
              >
                <strong>Last updated</strong>{" "}
                <bdt className="question">
                  <strong>December 09, 2024</strong>
                </bdt>
              </div>
              <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                <br />
              </div>
              <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                <br />
              </div>
              <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                <br />
              </div>
              <div align="center" style={{ textAlign: "left" }}>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                  >
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Open Sans",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                          }}
                        >
                          <bdt className="block-component" />
                          This Privacy Policy describes Our policies and procedures on the collection,
                          use and disclosure of Your information when You use the Service and tells
                          You about Your privacy rights and how the law protects You.
                        </span>
                      </bdt>
                    </bdt>
                    <bdt data-type="conditional-block">
                      <bdt className="block-component" />
                    </bdt>
                  </bdt>
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                    >
                      <bdt data-type="conditional-block">
                        <bdt data-type="body">
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Open Sans",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166
                            }}
                          >
                            <bdt className="block-component" />
                            We use Your Personal data to provide and improve the Service. By using the
                            Service, You agree to the collection and use of information in accordance
                            with this Privacy Policy. This Privacy Policy has been created with the help
                            of the{" "}
                            <span style={{ color: "rgb(0, 58, 250)" }}>
                              <bdt className="question">
                                <a
                                  href="https://www.privacypolicies.com/privacy-policy-generator/"
                                  target="_blank"
                                  data-custom-class="link"
                                >
                                  Privacy Policy Generator
                                </a>
                              </bdt>
                            </span>.{" "}
                          </span>
                        </bdt>
                      </bdt>
                      <bdt data-type="conditional-block">
                        <bdt className="block-component" />
                      </bdt>
                    </bdt>
                  </div>
                  <div className="MsoNormal" style={{ lineHeight: 1 }}>
                    <br />
                  </div>
                </div>
              </div>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    INTERPRETATION AND DEFINITIONS
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div align="center" style={{ textAlign: "left" }}>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_2"
                  style={{ lineHeight: "1.5" }}
                >
                  <strong>Interpretation</strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Open Sans",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166
                    }}
                  >
                    The words of which the initial letter is capitalized have meanings defined
                    under the following conditions. The following definitions shall have the
                    same meaning regardless of whether they appear in singular or in plural.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_2"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Open Sans",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166
                    }}
                  >
                    <strong>
                      Definitions
                    </strong>
                    <bdt className="statement-end-if-in-editor">
                      <strong />
                    </bdt>
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    For the purposes of this Privacy Policy:
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Account </strong> means a unique account created for You to
                    access our Service or parts of our Service.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Affiliate</strong> means an entity that controls, is controlled
                    by or is under common control with a party, where "control" means
                    ownership of 50% or more of the shares, equity interest or other
                    securities entitled to vote for election of directors or other managing
                    authority.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Company</strong> (referred to as either "the Company", "We",
                    "Us" or "Our" in this Agreement) refers to Cognitude, Chennai.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Cookies</strong> are small files that are placed on Your
                    computer, mobile device or any other device by a website, containing the
                    details of Your browsing history on that website among its many uses.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Country</strong> refers to: Tamil Nadu, India
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Device</strong> means any device that can access the Service
                    such as a computer, a cellphone or a digital tablet.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Personal Data</strong> is any information that relates to an
                    identified or identifiable individual.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Service</strong> refers to the Website.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Service Provider</strong> means any natural or legal person who
                    processes the data on behalf of the Company. It refers to third-party
                    companies or individuals employed by the Company to facilitate the
                    Service, to provide the Service on behalf of the Company, to perform
                    services related to the Service or to assist the Company in analyzing
                    how the Service is used.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Third-party Social Media Service</strong> refers to any website
                    or any social network website through which a User can log in or create
                    an account to use the Service.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Usage Data</strong> refers to data collected automatically,
                    either generated by the use of the Service or from the Service
                    infrastructure itself (for example, the duration of a page visit).
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Website</strong> refers to Tjunction, accessible from{" "}
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                      <bdt className="question">
                        <a
                          href="https://dev.tjunction.cognitude.co.in/"
                          target="_blank"
                          rel="external nofollow noopener"
                          data-custom-class="link"
                        >
                          https://dev.tjunction.cognitude.co.in/
                        </a>
                      </bdt>
                    </span>{" "}
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>You</strong> means the individual accessing or using the
                    Service, or the company, or other legal entity on behalf of which such
                    individual is accessing or using the Service, as applicable.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
              </div>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    COLLECTING AND USING YOUR PERSONAL DATA
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div
                align="center"
                data-custom-class="heading_2"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    Types of Data Collected
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1" }}>
                <br />
              </div>
              <div
                align="center"
                data-custom-class="heading_2"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    Personal Data
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div
                className="MsoNormal"
                style={{ textAlign: "justify", lineHeight: 1 }}
              >
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    While using Our Service, We may ask You to provide Us with certain
                    personally identifiable information that can be used to contact or identify
                    You. Personally identifiable information may include, but is not limited to:
                  </span>
                </div>
              </div>
              <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
                <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                  <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
                    <div
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        As a user of the Services, you agree not to:
                      </span>
                    </div>
                  </div>
                  <ul>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Email address
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          First name and last name
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Phone Number
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Address, State, Province, ZIP/Postal code, City
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Usage Data
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    USAGE DATA
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div align="center" style={{ textAlign: "left" }}>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                  >
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Open Sans",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                          }}
                        >
                          <bdt className="block-component" />
                          Usage Data is collected automatically when using the Service.
                        </span>
                      </bdt>
                    </bdt>
                    <bdt data-type="conditional-block">
                      <bdt className="block-component" />
                    </bdt>
                  </bdt>
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                    >
                      <bdt data-type="conditional-block">
                        <bdt data-type="body">
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Open Sans",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166
                            }}
                          >
                            <bdt className="block-component" />
                            Usage Data may include information such as Your Device's Internet Protocol
                            address (e.g. IP address), browser type, browser version, the pages of our
                            Service that You visit, the time and date of Your visit, the time spent on
                            those pages, unique device identifiers and other diagnostic data.
                          </span>
                        </bdt>
                      </bdt>
                      <bdt data-type="conditional-block">
                        <bdt className="block-component" />
                      </bdt>
                    </bdt>
                  </div>
                  <div className="MsoNormal" style={{ lineHeight: 1 }}>
                    <br />
                  </div>
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                    >
                      <bdt data-type="conditional-block">
                        <bdt data-type="body">
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Open Sans",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166
                            }}
                          >
                            <bdt className="block-component" />
                            When You access the Service by or through a mobile device, We may collect
                            certain information automatically, including, but not limited to, the type
                            of mobile device You use, Your mobile device unique ID, the IP address of
                            Your mobile device, Your mobile operating system, the type of mobile
                            Internet browser You use, unique device identifiers and other diagnostic
                            data.
                          </span>
                        </bdt>
                      </bdt>
                      <bdt data-type="conditional-block">
                        <bdt className="block-component" />
                      </bdt>
                    </bdt>
                  </div>
                  <div className="MsoNormal" style={{ lineHeight: 1 }}>
                    <br />
                  </div>
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                    >
                      <bdt data-type="conditional-block">
                        <bdt data-type="body">
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Open Sans",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166
                            }}
                          >
                            <bdt className="block-component" />
                            We may also collect information that Your browser sends whenever You visit
                            our Service or when You access the Service by or through a mobile device.
                          </span>
                        </bdt>
                      </bdt>
                      <bdt data-type="conditional-block">
                        <bdt className="block-component" />
                      </bdt>
                    </bdt>
                  </div>
                  <div className="MsoNormal" style={{ lineHeight: 1 }}>
                    <br />
                  </div>
                </div>
              </div>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    INFORMATION FROM THIRD-PARTY SOCIAL MEDIA SERVICES
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                The Company allows You to create an account and log in to use the Service
                through the following Third-party Social Media Services:
              </p>
              <ul>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Google
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Facebook
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Instagram
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                         Twitter
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          LinkedIn
                        </span>
                      </span>
                    </li>
                  </ul>
              <p className="content-css">
                If You decide to register through or otherwise grant us access to a
                Third-Party Social Media Service, We may collect Personal data that is
                already associated with Your Third-Party Social Media Service's account,
                such as Your name, Your email address, Your activities or Your contact list
                associated with that account.
              </p>
              <p className="content-css">
                You may also have the option of sharing additional information with the
                Company through Your Third-Party Social Media Service's account. If You
                choose to provide such information and Personal Data, during registration or
                otherwise, You are giving the Company permission to use, share, and store it
                in a manner consistent with this Privacy Policy.
              </p>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    TRACKING TECHNOLOGIES AND COOKIES
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                We use Cookies and similar tracking technologies to track the activity on
                Our Service and store certain information. Tracking technologies used are
                beacons, tags, and scripts to collect and track information and to improve
                and analyze Our Service. The technologies We use may include:
              </p>
              <ul>
                <li className="content-css">
                  <strong>Cookies or Browser Cookies.</strong> A cookie is a small file
                  placed on Your Device. You can instruct Your browser to refuse all Cookies
                  or to indicate when a Cookie is being sent. However, if You do not accept
                  Cookies, You may not be able to use some parts of our Service. Unless you
                  have adjusted Your browser setting so that it will refuse Cookies, our
                  Service may use Cookies.
                </li>
                <li className="content-css">
                  <strong>Web Beacons.</strong> Certain sections of our Service and our
                  emails may contain small electronic files known as web beacons (also
                  referred to as clear gifs, pixel tags, and single-pixel gifs) that permit
                  the Company, for example, to count users who have visited those pages or
                  opened an email and for other related website statistics (for example,
                  recording the popularity of a certain section and verifying system and
                  server integrity).
                </li>
              </ul>
              <p className="content-css">
                Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain
                on Your personal computer or mobile device when You go offline, while
                Session Cookies are deleted as soon as You close Your web browser. Learn
                more about cookies on the{" "}
                <a
                  href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking"
                  target="_blank"
                >
                  Privacy Policies website
                </a>{" "}
                article.
              </p>
              <p className="content-css">
                We use both Session and Persistent Cookies for the purposes set out below:
              </p>
              <ul>
                <li>
                  <p className="content-css">
                    <strong>Necessary / Essential Cookies</strong>
                  </p>
                  <p className="content-css">Type: Session Cookies</p>
                  <p className="content-css">Administered by: Us</p>
                  <p className="content-css">
                    Purpose: These Cookies are essential to provide You with services
                    available through the Website and to enable You to use some of its
                    features. They help to authenticate users and prevent fraudulent use of
                    user accounts. Without these Cookies, the services that You have asked
                    for cannot be provided, and We only use these Cookies to provide You
                    with those services.
                  </p>
                </li>
                <li>
                  <p className="content-css">
                    <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                  </p>
                  <p className="content-css">Type: Persistent Cookies</p>
                  <p className="content-css">Administered by: Us</p>
                  <p className="content-css">
                    Purpose: These Cookies identify if users have accepted the use of
                    cookies on the Website.
                  </p>
                </li>
                <li>
                  <p className="content-css">
                    <strong>Functionality Cookies</strong>
                  </p>
                  <p className="content-css">Type: Persistent Cookies</p>
                  <p className="content-css">Administered by: Us</p>
                  <p className="content-css">
                    Purpose: These Cookies allow us to remember choices You make when You
                    use the Website, such as remembering your login details or language
                    preference. The purpose of these Cookies is to provide You with a more
                    personal experience and to avoid You having to re-enter your preferences
                    every time You use the Website.
                  </p>
                </li>
              </ul>
              <p className="content-css">
                For more information about the cookies we use and your choices regarding
                cookies, please visit our Cookies Policy or the Cookies section of our
                Privacy Policy.
              </p>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    USE OF YOUR PERSONAL DATA
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">The Company may use Personal Data for the following purposes:</p>
              <ul>
                <li>
                  <p className="content-css">
                    <strong>To provide and maintain our Service</strong>, including to
                    monitor the usage of our Service.
                  </p>
                </li>
                <li>
                  <p className="content-css">
                    <strong>To manage Your Account:</strong> to manage Your registration as
                    a user of the Service. The Personal Data You provide can give You access
                    to different functionalities of the Service that are available to You as
                    a registered user.
                  </p>
                </li>
                <li>
                  <p className="content-css">
                    <strong>For the performance of a contract:</strong> the development,
                    compliance and undertaking of the purchase contract for the products,
                    items or services You have purchased or of any other contract with Us
                    through the Service.
                  </p>
                </li>
                <li>
                  <p className="content-css">
                    <strong>To contact You:</strong> To contact You by email, telephone
                    calls, SMS, or other equivalent forms of electronic communication, such
                    as a mobile application's push notifications regarding updates or
                    informative communications related to the functionalities, products or
                    contracted services, including the security updates, when necessary or
                    reasonable for their implementation.
                  </p>
                </li>
                <li>
                  <p className="content-css">
                    <strong>To provide You</strong> with news, special offers and general
                    information about other goods, services and events which we offer that
                    are similar to those that you have already purchased or enquired about
                    unless You have opted not to receive such information.
                  </p>
                </li>
                <li>
                  <p className="content-css">
                    <strong>To manage Your requests:</strong> To attend and manage Your
                    requests to Us.
                  </p>
                </li>
                <li>
                  <p className="content-css">
                    <strong>For business transfers:</strong> We may use Your information to
                    evaluate or conduct a merger, divestiture, restructuring,
                    reorganization, dissolution, or other sale or transfer of some or all of
                    Our assets, whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which Personal Data held by Us
                    about our Service users is among the assets transferred.
                  </p>
                </li>
                <li>
                  <p className="content-css">
                    <strong>For other purposes</strong>: We may use Your information for
                    other purposes, such as data analysis, identifying usage trends,
                    determining the effectiveness of our promotional campaigns and to
                    evaluate and improve our Service, products, services, marketing and your
                    experience.
                  </p>
                </li>
              </ul>
              <p className="content-css">We may share Your personal information in the following situations:</p>
              <ul>
                <li>
                <p className="content-css">
                  <strong>With Service Providers:</strong> We may share Your personal
                  information with Service Providers to monitor and analyze the use of our
                  Service, to contact You.
                  </p>
                </li>
                <li>
                <p className="content-css">
                  <strong>For business transfers:</strong> We may share or transfer Your
                  personal information in connection with, or during negotiations of, any
                  merger, sale of Company assets, financing, or acquisition of all or a
                  portion of Our business to another company.
                  </p>
                </li>
                <li>
                <p className="content-css">
                  <strong>With Affiliates:</strong> We may share Your information with Our
                  affiliates, in which case we will require those affiliates to honor this
                  Privacy Policy. Affiliates include Our parent company and any other
                  subsidiaries, joint venture partners or other companies that We control or
                  that are under common control with Us.
                  </p>
                </li>
                <li>
                <p className="content-css">
                  <strong>With business partners:</strong> We may share Your information
                  with Our business partners to offer You certain products, services or
                  promotions.
                  </p>
                </li>
                <li>
                <p className="content-css">
                  <strong>With other users:</strong> when You share personal information or
                  otherwise interact in the public areas with other users, such information
                  may be viewed by all users and may be publicly distributed outside. If You
                  interact with other users or register through a Third-Party Social Media
                  Service, Your contacts on the Third-Party Social Media Service may see
                  Your name, profile, pictures and description of Your activity. Similarly,
                  other users will be able to view descriptions of Your activity,
                  communicate with You and view Your profile.
                  </p>
                </li>
                <li>
                <p className="content-css">
                  <strong>With Your consent</strong>: We may disclose Your personal
                  information for any other purpose with Your consent.
                  </p>
                </li>
              </ul>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                   RETENTION OF YOUR PERSONAL DATA
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                The Company will retain Your Personal Data only for as long as is necessary
                for the purposes set out in this Privacy Policy. We will retain and use Your
                Personal Data to the extent necessary to comply with our legal obligations
                (for example, if we are required to retain your data to comply with
                applicable laws), resolve disputes, and enforce our legal agreements and
                policies.
              </p>
              <p className="content-css">
                The Company will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period of time, except when
                this data is used to strengthen the security or to improve the functionality
                of Our Service, or We are legally obligated to retain this data for longer
                time periods.
              </p>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    TRANSFER OF YOUR PERSONAL DATA
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                Your information, including Personal Data, is processed at the Company's
                operating offices and in any other places where the parties involved in the
                processing are located. It means that this information may be transferred to
                — and maintained on — computers located outside of Your state, province,
                country or other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
              </p>
              <p className="content-css">
                Your consent to this Privacy Policy followed by Your submission of such
                information represents Your agreement to that transfer.
              </p>
              <p className="content-css">
                The Company will take all steps reasonably necessary to ensure that Your
                data is treated securely and in accordance with this Privacy Policy and no
                transfer of Your Personal Data will take place to an organization or a
                country unless there are adequate controls in place including the security
                of Your data and other personal information.
              </p>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    DELETE YOUR PERSONAL DATA
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                You have the right to delete or request that We assist in deleting the
                Personal Data that We have collected about You.
              </p>
              <p className="content-css">
                Our Service may give You the ability to delete certain information about You
                from within the Service.
              </p>
              <p className="content-css">
                You may update, amend, or delete Your information at any time by signing in
                to Your Account, if you have one, and visiting the account settings section
                that allows you to manage Your personal information. You may also contact Us
                to request access to, correct, or delete any personal information that You
                have provided to Us.
              </p>
              <p className="content-css">
                Please note, however, that We may need to retain certain information when we
                have a legal obligation or lawful basis to do so.
              </p>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    DISCLOSURE OF YOUR PERSONAL DATA
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div
                align="center"
                data-custom-class="heading_2"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                  Business Transactions
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                If the Company is involved in a merger, acquisition or asset sale, Your
                Personal Data may be transferred. We will provide notice before Your
                Personal Data is transferred and becomes subject to a different Privacy
                Policy.
              </p>
              <div
                align="center"
                data-custom-class="heading_2"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                  Law Enforcement
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                Under certain circumstances, the Company may be required to disclose Your
                Personal Data if required to do so by law or in response to valid requests
                by public authorities (e.g. a court or a government agency).
              </p>
              <div
                align="center"
                data-custom-class="heading_2"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                 Other Legal Requirements
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                The Company may disclose Your Personal Data in the good faith belief that
                such action is necessary to:
              </p>
              <ul>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Comply with a legal obligation
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                         Protect and defend the rights or property of the Company
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Prevent or investigate possible wrongdoing in connection with the Service
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                         Protect the personal safety of Users of the Service or the public
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                        Protect against legal liability
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                  SECURITY OF YOUR PERSONAL DATA
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                The security of Your Personal Data is important to Us, but remember that no
                method of transmission over the Internet, or method of electronic storage is
                100% secure. While We strive to use commercially acceptable means to protect
                Your Personal Data, We cannot guarantee its absolute security.
              </p>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    CHILDREN'S PRIVACY
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                Our Service does not address anyone under the age of 13. We do not knowingly
                collect personally identifiable information from anyone under the age of 13.
                If You are a parent or guardian and You are aware that Your child has
                provided Us with Personal Data, please contact Us. If We become aware that
                We have collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that information
                from Our servers.
              </p>
              <p className="content-css">
                If We need to rely on consent as a legal basis for processing Your
                information and Your country requires consent from a parent, We may require
                Your parent's consent before We collect and use that information.
              </p>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    LINKS TO OTHER WEBSITES
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                Our Service may contain links to other websites that are not operated by Us.
                If You click on a third party link, You will be directed to that third
                party's site. We strongly advise You to review the Privacy Policy of every
                site You visit.
              </p>
              <p className="content-css">
                We have no control over and assume no responsibility for the content,
                privacy policies or practices of any third party sites or services.
              </p>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    CHANGES TO THIS PRIVACY POLICY
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                We may update Our Privacy Policy from time to time. We will notify You of
                any changes by posting the new Privacy Policy on this page.
              </p>
              <p className="content-css">
                We will let You know via email and/or a prominent notice on Our Service,
                prior to the change becoming effective and update the "Last updated" date at
                the top of this Privacy Policy.
              </p>
              <p className="content-css">
                You are advised to review this Privacy Policy periodically for any changes.
                Changes to this Privacy Policy are effective when they are posted on this
                page.
              </p>
              <div
                align="center"
                data-custom-class="heading_1"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span id="ip" style={{ fontSize: 19 }}>
                    CONTACT US
                  </span>
                </strong>
              </div>
              <div align="center" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <p className="content-css">
                If you have any questions about this Privacy Policy, You can contact us:
              </p>
              <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: 15 }}>
                    By email: {" "}
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                      <bdt className="question">
                        <a
                          href="/cdn-cgi/l/email-protection"
                          className="__cf_email__"
                          data-cfemail="741d1a121b34171b131a1d000110115a171b5a1d1a"
                          data-custom-class="link"
                        >
                           [email&nbsp;protected]
                        </a>
                      </bdt>
                    </span>{" "}
                  </span>
                </div>
            </>
          </div>
        </div>
      </div >
    </>
  );
};

export default PrivacyPolicy;
