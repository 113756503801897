import ProfileService from "../services/profile.service";
import {
  TRAINING_EXPERIENCE,
  JOB_EXPERIENCE,
  QUALIFICATIONS,
  CERTIFICATIONS,
  AWARDS,
  SKILLS,
  RECORD_HIDE,
  SECTION_HIDE,
  DELETE,
  DESCRIPTION,
  CREATE_NEW_PROFILE,
  ENDORSE,
  ENDORSE_DETAILS,
  EDIT_PROFILE_MENU,
  SET_PRIMARY_PROFILE,
  ACTIVE_PROFILE,
  USER_PROFILES,
  CREATE_INTEREST,
  GET_INTERESTS,
  GET_PROFILE,
  UPLOAD_IMAGE,
  PROFILE_PREVIEW,
} from "./types";
import { toast } from "react-toastify";

export const getProfileDetails =
  (getAll = 0) =>
  async (dispatch) => {
    dispatch(addUserProfile([]));
    dispatch(setActiveProfile([]));
    const resultData = await ProfileService.getProfileDetails(getAll);

    if (resultData?.status && resultData.status === true) {
      let activeProfile = ProfileService.getActiveProfile(
        resultData.profileData,
      ).shift();
      dispatch(addUserProfile(resultData.profileData));
      dispatch(setActiveProfile(activeProfile));
    }
  };

export const setPrimaryProfile = (activeProfile) => async (dispatch) => {
  const resultData = await ProfileService.setPrimaryProfile(
    activeProfile.user_profile_id,
  );

  if (resultData?.status && resultData.status === true) {
    dispatch({
      type: SET_PRIMARY_PROFILE,
      payload: {
        profileID: activeProfile.user_profile_id,
      },
    });
    dispatch(setActiveProfile(activeProfile));
  }
};

// to get all profiles
export const addUserProfile = (userProfiles) => {
  return {
    type: USER_PROFILES,
    userProfiles: userProfiles,
  };
};

// to set active profile
export const setActiveProfile = (profile) => {
  return {
    type: ACTIVE_PROFILE,
    activeProfile: profile,
  };
};

export const setProfilePreview = (profile) => {
  return {
    type: PROFILE_PREVIEW,
    profilepreview: profile,
  };
};

export const trainingExperience = (trainingData) => async (dispatch) => {
  try {
    const res = await ProfileService.trainingExperience(trainingData);

    if (res.expData.status) {
      let message = trainingData.userexperience_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: TRAINING_EXPERIENCE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const jobExperience = (jobData) => async (dispatch) => {
  try {
    const res = await ProfileService.jobExperience(jobData);

    if (res.jobData.status) {
      let message = jobData.user_job_experience_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: JOB_EXPERIENCE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const qualification = (qualData) => async (dispatch) => {
  try {
    const res = await ProfileService.qualification(qualData);

    if (res.qualData.status) {
      let message = qualData.userqualification_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: QUALIFICATIONS,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const certificate = (certificateData) => async (dispatch) => {
  try {
    const res = await ProfileService.certificate(certificateData);

    if (res.certData.status) {
      let message = certificateData.user_certificates_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: CERTIFICATIONS,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const award = (awardData) => async (dispatch) => {
  try {
    const res = await ProfileService.award(awardData);

    if (res.awarData.status) {
      let message = awardData.user_awards_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: AWARDS,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const skill = (skillData) => async (dispatch) => {
  try {
    const res = await ProfileService.skill(skillData);

    if (res.skilData.status) {
      let message = skillData.userskill_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: SKILLS,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const description = (descriptionData) => async (dispatch) => {
  try {
    const res = await ProfileService.trainerDescription(descriptionData);

    dispatch({
      type: DESCRIPTION,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteRecord = (deleteData) => async (dispatch) => {
  try {
    const res = await ProfileService.deleteRecord(deleteData);

    if (res.enableData.status) {
      toast.success("Deleted");
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: DELETE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const recordHide = (hideData) => async (dispatch) => {
  try {
    const res = await ProfileService.showAndHide(hideData);

    dispatch({
      type: RECORD_HIDE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createNewProfile =
  (profileData, key, profiles) => async (dispatch) => {
    try {
      const res = await ProfileService.newProfile(profileData);
      let message = profileData.user_profile_id ? "Updated" : "Added";
      toast.success(message);

      if (!profileData.user_profile_id) {
        profiles[key]["user_profile_id"] = res.details.data;
      }

      dispatch({
        type: CREATE_NEW_PROFILE,
        payload: res,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const sectionShow = (sectionData) => async (dispatch) => {
  try {
    const res = await ProfileService.sectionShow(sectionData);

    dispatch({
      type: SECTION_HIDE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const endorse = (endorseData, index, skill) => async (dispatch) => {
  try {
    const res = await ProfileService.endorse(endorseData);
    skill[index]["count"] = res.count;
    skill[index]["btnText"] = res.btnText;

    if (res.status) {
      toast.success("Endorsed!");
    } else {
      toast.success("Try again!");
    }

    dispatch({
      type: ENDORSE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getEndorseDetails = (details) => async (dispatch) => {
  try {
    const res = await ProfileService.getEndorseDetails(details);

    dispatch({
      type: ENDORSE_DETAILS,
      payload: res.details,
    });
  } catch (err) {
    console.log(err);
  }
};

export const editProfileMenu = (profileMenu) => async (dispatch) => {
  try {
    const res = await ProfileService.editProfileMenu(profileMenu);

    if (res.details.status) {
      toast.success("Updated!");
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: EDIT_PROFILE_MENU,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addInterest = (interestData) => async (dispatch) => {
  try {
    const res = await ProfileService.createInterest(interestData);

    if (res.status) {
      toast.success("Added!");
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: CREATE_INTEREST,
      payload: res.status ? res.profileData.data.interest : "",
    });
  } catch (err) {
    console.log(err);
  }
};

export const getInterests = () => async (dispatch) => {
  try {
    const res = await ProfileService.getInterests();
    dispatch({
      type: GET_INTERESTS,
      payload: res.details.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProfile = (profileData) => async (dispatch) => {
  try {
    const res = await ProfileService.getProfile(profileData);

    dispatch({
      type: GET_PROFILE,
      payload: res.details.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setImages = (data) => async (dispatch) => {
  try {
    const res = await ProfileService.uploadImage(data);

    dispatch({
      type: UPLOAD_IMAGE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};
