import axios from "axios";
import moment from "moment-timezone";
import {
  getTokenExpires,
  getUser,
  setUserSession,
  setTokenExpires,
  removeUserSession,
  getRefreshToken,
} from "../utils/UserDetails";

const baseURL = process.env.REACT_APP_BACKEND_BASEURL + "/api";
const headerData = {
  Accept: "application/json",
};

let token = getUser();
let refreshToken = getRefreshToken() ? getRefreshToken() : null;
let isRefreshing = false;
let refreshPromise = null;
let pendingRequests = [];

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: headerData,
});

if (token) {
  axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getUser();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = "";
    }
    const currentTime = moment().tz("UTC").unix();
    const tokenExpiresAt = getTokenExpires()
      ? parseInt(getTokenExpires(), 10)
      : null;

    if (tokenExpiresAt && tokenExpiresAt - currentTime < 60) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshPromise = refreshAuthToken();
      }
      return refreshPromise
        .then((newToken) => {
          config.headers.Authorization = `Bearer ${newToken}`;
          return config;
        })
        .catch((error) => {
          removeUserSession();
          window.location.href = "/";
          return Promise.reject(error);
        });
    }
    return config;
  },
  (err) => Promise.reject(err),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry && token) {
      originalRequest._retry = true;
      if (!isRefreshing) {
        isRefreshing = true;
        refreshPromise = refreshAuthToken();
      }
      return refreshPromise
        .then((newToken) => {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        })
        .catch((error) => {
          removeUserSession();
          window.location.href = "/";
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  },
);

async function refreshAuthToken() {
  try {
    const response = await axios.post(
      `${baseURL}/refresh-token`,
      { refresh_token: refreshToken },
      { headers: headerData },
    );
    const { api_token, expiry_time, newRefreshToken } = response.data;
    setUserSession(api_token); // Save new tokens
    setTokenExpires(expiry_time); // Update token expiry
    axiosInstance.defaults.headers.Authorization = `Bearer ${api_token}`;
    isRefreshing = false;
    refreshPromise = null;
    return api_token;
  } catch (error) {
    removeUserSession();
    window.location.href = "/";
    isRefreshing = false;
    refreshPromise = null;
    throw error;
  }
}

export default axiosInstance;
