import Axios from "../config/axios";

class ProfileService {
  getProfileDetails = async (getAll = 0) => {
    let returnData;
    let data = {
      getall: getAll,
    };

    await Axios.post("/get_profile_details", data)
      .then(function (response) {
        if (response.data.status) {
          returnData = {
            status: true,
            profileData: response.data.data,
            isFirst: response.data.isFirst,
            isPermission: response.data.isPermission,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  getAllUserProfileDetails = async (data) => {
    const url = process.env.REACT_APP_UPLOADURL;
    let returnData;
    await Axios.post(url, data)
      .then(function (response) {
        returnData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return returnData;
  };

  buttonClick = async (data) => {
    let returnData;
    await Axios.post(
      "/track_click",
      {},
      {
        headers: {
          "X-User-Identifier": data,
        },
      },
    )
      .then(function (response) {
        returnData = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return returnData;
  };

  trainerDescription(descriptionData) {
    let returnData;

    Axios.post("/edit-trainer-description", descriptionData)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            profileData: response.data.result,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  }

  trainingExperience = async (trainingObj) => {
    let returnData;

    await Axios.post("/add_experience", trainingObj)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            expData: response.data.result,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  showAndHide = async (showAndHideData) => {
    let returnData;

    await Axios.post("/edit-show-and-hide", showAndHideData)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            enableData: response.data.result,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  deleteRecord = async (deleteData) => {
    let returnData;

    await Axios.post("/delete", deleteData).then(function (response) {
      if (response.data.result.status === true) {
        returnData = {
          status: true,
          enableData: response.data.result,
        };
      } else {
        returnData = {
          status: false,
        };
      }
    });

    return returnData;
  };

  jobExperience = async (jobObj) => {
    let returnData;

    await Axios.post("/add-job-experience", jobObj)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            jobData: response.data.result,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  qualification = async (qualObj) => {
    let returnData;

    await Axios.post("/add-education", qualObj)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            qualData: response.data.result,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  certificate = async (certObj) => {
    let returnData;

    await Axios.post("/add-certificate", certObj)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            certData: response.data.result,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  award = async (awardObj) => {
    let returnData;

    await Axios.post("/add_award", awardObj)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            awarData: response.data.result,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  skill = async (skillObj) => {
    let returnData;

    await Axios.post("/add_skill", skillObj)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            skilData: response.data.result,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  endorse = async (endorseObj) => {
    let returnData;

    await Axios.post("/endorse", endorseObj)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            type: response.data.result.type,
            btnText: response.data.result.btnText,
            count: response.data.result.count,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  getEndorseDetails = async (endorseData) => {
    let returnData;

    await Axios.post("/get-endorsement", endorseData)
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            details: response.data.result.details,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  uploadImage = async (imageData) => {
    let returnData;

    await Axios.post("/upload-image", imageData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data.result.status === true) {
          returnData = {
            status: true,
            url: response.data.result.url,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  getProfileViewDetails = async (profileObj) => {
    let returnData;

    await Axios.post("/get-profile-view", profileObj)
      .then(function (response) {
        returnData = {
          details: response.data.details,
        };
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  editProfileMenu = async (profileObj) => {
    let returnData;

    await Axios.post("/edit-profile-menu", profileObj)
      .then(function (response) {
        returnData = {
          details: response.data.details,
        };
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  getProfile = async (profileId) => {
    let returnData;

    await Axios.post("/get-profile", profileId)
      .then(function (response) {
        returnData = {
          details: response.data.details,
        };
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  newProfile = async (profileObj) => {
    let returnData;

    await Axios.post("/create-new-profile", profileObj)
      .then(function (response) {
        if (response.data.details.status === true) {
          returnData = {
            status: true,
            details: response.data.details,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  getInterests = async () => {
    let returnData;

    await Axios.post("/get-interests")
      .then(function (response) {
        if (response.data.details.status === true) {
          returnData = {
            status: true,
            details: response.data.details,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  createInterest = async (interestData) => {
    let returnData;

    await Axios.post("/add-interests", interestData)
      .then(function (response) {
        console.log(response);
        if (response?.data?.status === true) {
          returnData = response?.data;
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(returnData);
    return returnData;
  };

  getActiveProfile = (profiles) => {
    return profiles.filter((item) => {
      return item.user_profile_default;
    });
  };

  defaultProfilePic = () => {
    return process.env.PUBLIC_URL + "/images/icons/profile1.jpg";
  };

  setPrimaryProfile = async (profileID) => {
    let returnData = [];
    let profileData = {
      profile_id: profileID,
    };

    await Axios.post("/set-primary-profile", profileData).then(function (
      response,
    ) {
      returnData = response.data;
    });

    return returnData;
  };

  uploadFile = async (fileData) => {
    let returnData;

    await Axios.post("/store-ai-profile", fileData)
      .then(function (response) {
        if (response.data) {
          returnData = {
            status: true,
            data: response.data,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  sectionShow = async (showData) => {
    let returnData;

    await Axios.post("/section-show", showData)
      .then(function (response) {
        if (response.data.details.status === true) {
          returnData = {
            status: true,
          };
        } else {
          returnData = {
            status: false,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };

  setProfileTheme = (data) => {
    Axios.post("/set-profile-theme", data)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
const profileServiceInstance = new ProfileService();
export default profileServiceInstance;
