import Axios from "../config/axios.js";

class AuthService {
  userLogin = (loginData) => {
    return Axios.post("user-login", loginData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  registerLearningHub = (formdata) => {
    return Axios.post("/register/learninghub", formdata)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkUniqueEmail = (email) => {
    let emailData = {
      email: email,
    };
    return Axios.post("/check-unique-email", emailData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  verifyEmail = (verifyData) => {
    return Axios.post("/check-verify-email", verifyData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkUniquePhone = (phoneData) => {
    return Axios.post("/check-unique-phone", phoneData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  googleLogin = (googleData) => {
    return Axios.post("/user-login", googleData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  googleRegister = (googleData) => {
    return Axios.post("/register/learninghub", googleData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkUserExist = (googleData) => {
    return Axios.post("/check-unique-email", googleData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  otpGeneration = (googleData) => {
    return Axios.post("/login-otp-generation", googleData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  userLogout = () => {
    return Axios.post("/user-logout", [])
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  forgotPassword = async (formData) => {
    return Axios.post("/forgot-password", formData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
        return { status: false, error: error };
      });
  };
  verifyLink = async (token) => {
    return Axios.post("/verify-link", token)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
        return { status: false, error: error };
      });
  };
  changePassword = async (formData) => {
    return Axios.post("/reset-password", formData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
        return { status: false, error: error };
      });
  };
}

const authServiceInstance = new AuthService();
export default authServiceInstance;
